const LATITUDE = "lat";
const LONGITUDE = "lon";
const CENSUS_YEAR = "censusYear";
const FORMAT = "format";
const FCC_AREA_API = "https://geo.fcc.gov/api/census/area";

function getCookieValue(a) {
  const b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
}

const date = new Date();
date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
const expString = '; expires=' + date.toGMTString();
var tzip = getCookieValue('vzip') || '';
var tcity = getCookieValue('vcity') || '';
var tstate = getCookieValue('vstate') || '';
var tlat = getCookieValue('vlat') || '';
var tlong = getCookieValue('vlong') || '';
var returnStr = 'na|na|na|na|na';
var pathname = window.location.pathname || '';
// Only execute if we do not have existing info, and are not an "/errors/" page

if (!pathname.match('/errors/') && (tcity === '' || tstate === '' || tzip === '')) { 
  $.getJSON('https://ipapi.co/jsonp?key=0vDX1JW1XQjRhnB37LvkTo7aXROjdvTjUcU4WJ5BmGpxmRMp1F&callback=?', function (data) {
    tzip = data.postal;
    tcity = data.city;
    tstate = data.region;
    tlat = data.latitude;
    tlong = data.longitude;
    document.cookie = 'vzip=' + tzip + '; path=/;domain=.bcbsnd.com;' + expString;
    document.cookie = 'vcity=' + tcity + '; path=/;domain=.bcbsnd.com;' + expString;
    document.cookie = 'vstate=' + tstate + '; path=/;domain=.bcbsnd.com;' + expString;
    document.cookie = 'vlat=' + tlat + '; path=/;domain=.bcbsnd.com;' + expString;
    document.cookie = 'vlong=' + tlong + '; path=/;domain=.bcbsnd.com;' + expString;
    returnStr = [tzip, tcity, tstate, tlat, tlong].join('|');
  }).done(function () {
    // Set Data Layer
    if (!digitalData.visitor) {
      Object.assign(window.digitalData, {visitor: {}});
    }
    Object.assign(window.digitalData.visitor, {
      zipcode: tzip,
      city: tcity,
      state: tstate,
      lat: tlat,
      long: tlong,
    });
    //RM: Removing Google County lookup from non-target pages.
    $(document).trigger('getCountyFromFCC');
  })
} else {
  returnStr = [tzip, tcity, tstate, tlat, tlong].join('|');
}

function getCountyFromFCC() {
  let tcounty = (getCookieValue('vcounty')) || '';
  let returnStrCounty = '';
  const tlat = window.digitalData.visitor.lat || '';
  const tlong = window.digitalData.visitor.long || '';

  if (!tcounty && tlat) {
    const latParameter = [LATITUDE, tlat].join("=");
    const lonParameter = [LONGITUDE, tlong].join("=");
    const censusYearParameter = [CENSUS_YEAR, "2020"].join("=");
    const formatParameter = [FORMAT, "json"].join("=");
    const parameters = [latParameter, lonParameter, censusYearParameter, formatParameter].join("&");
    const url = [FCC_AREA_API, parameters].join("?");

    $.getJSON(url, null, data => {
      if (data && data.results && data.results.length > 0) {
        const result = data.results[0];
        const countyName = result['county_name'];
        tcounty = countyName;
        document.cookie = "vcounty=" + countyName + '; path=/;domain=.bcbsnd.com;' + expString;
        returnStrCounty = countyName;
      } else {
        console.error('FCC response error: ' + data.statusText);
        returnStrCounty = 'error';
      }
    }).done(() => {
      // Set Data Layer
      if (!digitalData.visitor) {
        Object.assign(window.digitalData, {visitor: {}})
      }
      Object.assign(window.digitalData.visitor, {
        county: tcounty,
      });
    })
  } else {
    returnStrCounty = tcounty;
  }
}

$(document).on('getCountyFromFCC', getCountyFromFCC);
