var pathname = window.location.pathname;
var maxAge = 60 * 60 * 24 * 365; // one year
var updateDigitalLayerWithLastSection;
var tzip = getCookieValue('vzip') || '';
var tcity = getCookieValue('vcity') || '';
var tstate = getCookieValue('vstate') || '';
var tlat = getCookieValue('vlat') || '';
var tlong = getCookieValue('vlong') || '';
var tcounty = getCookieValue('vcounty') || '';
var addVisitorInformation = {
  zipcode: tzip,
  city: tcity,
  state: tstate,
  lat: tlat,
  long: tlong,
  county: tcounty
}
if (!digitalData.visitor) {
  $.extend(true, window.digitalData, { visitor: {} });
}

if (pathname.match('^/members')) {
  document.cookie = 'lastSection=/members;path=/;domain=.bcbsnd.com;max-age=' + maxAge;
  updateDigitalLayerWithLastSection = {
    lastSection: '/members',
  };
  $.extend(true, window.digitalData.visitor, addVisitorInformation);
  $.extend(true, window.digitalData.visitor, updateDigitalLayerWithLastSection);
} else if (pathname.match('^/providers')) {
  document.cookie = 'lastSection=/providers;path=/;domain=.bcbsnd.com;max-age=' + maxAge;
  updateDigitalLayerWithLastSection = {
    lastSection: '/providers',
  };
  $.extend(true, window.digitalData.visitor, addVisitorInformation);
  $.extend(true, window.digitalData.visitor, updateDigitalLayerWithLastSection);
} else if (pathname.match('^/employers')) {
  document.cookie = 'lastSection=/employers;path=/;domain=.bcbsnd.com;max-age=' + maxAge;
  updateDigitalLayerWithLastSection = {
    lastSection: '/employers',
  };
  $.extend(true, window.digitalData.visitor, addVisitorInformation);
  $.extend(true, window.digitalData.visitor, updateDigitalLayerWithLastSection);
} else if (pathname.match('^/producers')) {
  document.cookie = 'lastSection=/producers;path=/;domain=.bcbsnd.com;max-age=' + maxAge;
  updateDigitalLayerWithLastSection = {
    lastSection: '/producers',
  };
  $.extend(true, window.digitalData.visitor, addVisitorInformation);
  $.extend(true, window.digitalData.visitor, updateDigitalLayerWithLastSection);
}else {
  $.extend(true, window.digitalData.visitor, addVisitorInformation);
}

if (pathname.match('^/members[/]?$') || pathname.match('^/providers[/]?$') || pathname.match('^/employers[/]?$') || pathname.match('^/producers[/]?$')||pathname.match('^/$')) {
  var updateDigitalLayerWithSiteSection = {
    siteSection: 'Homepage',
  };
  $.extend(true, window.digitalData.page, updateDigitalLayerWithSiteSection);
} else if(pathname.match('^/employers/employer-news[/]?$') || pathname.match('^/providers/policies-precertification/medical-policy[/]?$') || pathname.match('^/providers/news-resources/healthcare-news[/]?$') || pathname.match('^/providers/policies-precertification/reimbursement-policy[/]?$')){
  var updateDigitalLayerWithSiteSection = {
    siteSection: 'search',
  };
  $.extend(true, window.digitalData.page, updateDigitalLayerWithSiteSection);
}
